// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const questionsApi = createApi({
	reducerPath: 'questionsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: [
		'admin-questions',
		'answers',
		'question-scores',
		'questions-condition',
		'position-admin-questions',
		'questions-types'
	],
	endpoints: (build) => ({
		getQuestionScores: build.query({
			query: ({ organizationId, auditKey }) =>
				`/api/questions/scores?organizationId=${organizationId}&auditKey=${auditKey}`,
			providesTags: ['question-scores']
		}),
		getQuestionConditions: build.query({
			query: ({ auditKey }) => `/api/questions/conditions?auditKey=${auditKey}`,
			providesTags: ['questions-condition']
		}),
		areQuestionConditionsFulfilled: build.query({
			query: ({ questionKey }) => `/api/questions/conditions/fulfilled?questionKey=${questionKey}`,
			providesTags: ['questions-condition']
		}),
		getQuestionChoices: build.query({
			query: ({ auditKey, languageCode }) => `/api/questions/choices?auditKey=${auditKey}&languageCode=${languageCode}`
		}),
		getQuestionAnswers: build.query({
			query: ({ auditKey, organizationId }) =>
				`/api/questions/answers?organizationId=${organizationId}&auditKey=${auditKey}`,
			providesTags: ['answers']
		}),
		getAdminQuestions: build.query({
			query: ({ auditId, languageCode }) => `/api/questions/admin/audit/${auditId}?lg=${languageCode}`,
			providesTags: ['admin-questions']
		}),
		questionsByType: build.query({
			query: ({ questionType }) => `/api/questions?type=${questionType}`,
			providesTags: ['questions-types']
		}),
		getQuestionPosition: build.query({
			query: ({ questionKey }) => `/api/questions/${questionKey}/position`,
			providesTags: ['position-admin-questions']
		}),
		getAdminQuestion: build.query({
			query: ({ questionKey, languageCode }) => `/api/questions/admin/${questionKey}?lg=${languageCode}`
		}),
		getAdminQuestionKeys: build.query({
			query: ({ languageCode }) => `/api/questions/admin/questions/keys?lg=${languageCode}`
		}),
		updateQuestionConditions: build.mutation({
			query: (body: {
				questionKey: string;
				conditions: { questionKey: string; conditionQuestionKey: string; choiceKey: string }[];
			}) => ({
				url: `/api/questions/conditions`,
				method: 'PUT',
				body
			})
		}),
		updateQuestionValidationTranslation: build.mutation({
			query: (body: { questionKey: string; languageCode: string; isValidate: boolean }) => ({
				url: `/api/questions/translations/validation`,
				method: 'PUT',
				body
			})
		}),
		updateQuestionsIndex: build.mutation({
			query: (body: { indexes: { key: string; index: number }[] }) => ({
				url: `/api/questions/indexes`,
				method: 'PUT',
				body
			})
		}),
		syncQuestionTranslations: build.mutation({
			query: (body: {
				translations: { title: string; label: string; tooltip: string; languageCode: string; questionKey: string }[];
			}) => ({
				url: `/api/questions/translations`,
				method: 'PUT',
				body
			})
		}),
		editSingleQuestion: build.mutation({
			query: (body: {
				blockKey?: string;
				key: string;
				displayType: string;
				isAnswerable: boolean;
				isScoreVisible: boolean;
				choices: {
					key: string;
					score: number;
					questionKey: string;
					texts: { label: string; languageCode: string; choiceKey: string };
					index: number;
				}[];
				status: 'published' | 'draft';
				auditId: number;
			}) => ({
				url: `/api/questions/single`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['position-admin-questions']
		}),
		editKpi: build.mutation({
			query: (body: { key: string; displayType: string; status: 'published' | 'draft'; auditId: number }) => ({
				url: `/api/questions/kpi`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['position-admin-questions']
		}),
		editMultipleQuestion: build.mutation({
			query: (body: {
				blockKey?: string;
				key: string;
				displayType: string;
				isAnswerable: boolean;
				isScoreVisible: boolean;
				choices: {
					key: string;
					score: number;
					questionKey: string;
					texts: { label: string; languageCode: string; choiceKey: string };
					index: number;
				}[];
				status: 'published' | 'draft';
				auditId: number;
			}) => ({
				url: `/api/questions/multiple`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['position-admin-questions']
		}),
		editComputedQuestion: build.mutation({
			query: (body: {
				blockKey?: string;
				key: string;
				isAnswerable: boolean;
				isScoreVisible: boolean;
				status: 'published' | 'draft';
				auditId: number;
			}) => ({
				url: `/api/questions/computed`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['position-admin-questions']
		}),
		editFreeQuestion: build.mutation({
			query: (body: {
				blockKey?: string;
				key: string;
				score: number;
				isAnswerable: boolean;
				isScoreVisible: boolean;
				status: 'published' | 'draft';
				auditId: number;
			}) => ({
				url: `/api/questions/free`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['position-admin-questions']
		}),
		deleteQuestion: build.mutation({
			query: ({ key }) => ({
				url: `/api/questions/${key}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['admin-questions']
		}),
		addTip: build.mutation({
			query: (body: { key: string; auditId: number }) => ({
				url: `/api/questions/add-tip`,
				method: 'POST',
				body
			})
		}),
		addSingleQuestion: build.mutation({
			query: (body: {
				blockKey?: string;
				key: string;
				displayType: string;
				choices: { key: string; score: number }[];
				auditId: number;
			}) => ({
				url: `/api/questions/add-single`,
				method: 'POST',
				body
			})
		}),
		addKpiQuestion: build.mutation({
			query: (body: { blockKey?: string; key: string; displayType: string; auditId: number }) => ({
				url: `/api/questions/add-kpi`,
				method: 'POST',
				body
			})
		}),
		addMultipleQuestion: build.mutation({
			query: (body: {
				blockKey?: string;
				key: string;
				displayType: string;
				choices: { key: string; score: number }[];
				auditId: number;
			}) => ({
				url: `/api/questions/add-multiple`,
				method: 'POST',
				body
			})
		}),
		addFreeQuestion: build.mutation({
			query: (body: { blockKey?: string; key: string; score: number; auditId: number }) => ({
				url: `/api/questions/add-free`,
				method: 'POST',
				body
			})
		}),
		addComputedQuestion: build.mutation({
			query: (body: { blockKey?: string; key: string; auditId: number }) => ({
				url: `/api/questions/add-computed`,
				method: 'POST',
				body
			})
		})
	})
});

export const {
	useAddComputedQuestionMutation,
	useAddFreeQuestionMutation,
	useAddMultipleQuestionMutation,
	useAddSingleQuestionMutation,
	useAddKpiQuestionMutation,
	useAddTipMutation,
	useDeleteQuestionMutation,
	useEditComputedQuestionMutation,
	useEditFreeQuestionMutation,
	useEditMultipleQuestionMutation,
	useEditSingleQuestionMutation,
	useEditKpiMutation,
	useGetAdminQuestionKeysQuery,
	useGetAdminQuestionQuery,
	useGetAdminQuestionsQuery,
	useGetQuestionAnswersQuery,
	useGetQuestionChoicesQuery,
	useGetQuestionConditionsQuery,
	useGetQuestionPositionQuery,
	useGetQuestionScoresQuery,
	useSyncQuestionTranslationsMutation,
	useUpdateQuestionValidationTranslationMutation,
	useUpdateQuestionsIndexMutation,
	useUpdateQuestionConditionsMutation,
	useLazyGetAdminQuestionKeysQuery,
	useLazyGetAdminQuestionQuery,
	useLazyGetAdminQuestionsQuery,
	useLazyGetQuestionAnswersQuery,
	useLazyGetQuestionChoicesQuery,
	useLazyGetQuestionConditionsQuery,
	useLazyGetQuestionPositionQuery,
	useLazyGetQuestionScoresQuery,
	useAreQuestionConditionsFulfilledQuery,
	useLazyAreQuestionConditionsFulfilledQuery,
	useQuestionsByTypeQuery,
	useLazyQuestionsByTypeQuery
} = questionsApi;
