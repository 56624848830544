import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';

// Externals

import { CssBaseline, NoSsr, ThemeProvider } from '@mui/material';
import { inMemoryPersistence, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';
import { AuthProvider, FirestoreProvider } from 'reactfire';
import { Toaster } from 'react-hot-toast';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { FirebaseAppProvider } from 'reactfire';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';
import Cookies from 'universal-cookie';

// Component
import { BlocksProvider } from 'components/legacy/components/blocks';

// Utils
import { toastOptions } from 'components/legacy/config';
import { createCustomTheme } from 'components/legacy/theme/freewayteam';
import * as constants from 'components/legacy/constants';
import * as config from 'components/legacy/config';

// I18N
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

// import plural rules for all locales
import { en as pluralsEn, fr as pluralsFr, nl as pluralsNl, pt_PT as pluralsPt } from 'make-plural/plurals';

// Store
import store from 'components/legacy/store';
import { messages as messagesFr } from 'locales/fr/messages';
import { messages as messagesEn } from 'locales/en/messages';
import { messages as messagesNl } from 'locales/nl/messages';
import { messages as messagesPt } from 'locales/pt-PT/messages';
import { initializeApp } from 'firebase/app';
import { isBrowser } from 'lib/isBrowser';
import Head from 'next/head';
import Script from 'next/script';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ColorModeContext } from 'components/legacy/theme/freewayteam/color-mode-context';

const cookies = new Cookies();
cookies.get('preferred-language');
const lge = cookies.get('preferred-language') || 'fr';

i18n.load('en', messagesEn);
i18n.load('fr', messagesFr);
i18n.load('nl', messagesNl);
i18n.load('pt', messagesPt);
i18n.loadLocaleData('fr', { plurals: pluralsFr });
i18n.loadLocaleData('en', { plurals: pluralsEn });
i18n.loadLocaleData('nl', { plurals: pluralsNl });
i18n.loadLocaleData('pt', { plurals: pluralsPt });
i18n.activate(lge ? lge : 'fr');

const App = (props: AppProps) => {
	const { Component, pageProps } = props;
	// we initialize the firebase app
	// using the configuration that we defined above
	const app = initializeApp(config.firebase);

	// make sure we're not using IndexedDB when SSR
	// as it is only supported on browser environments
	const persistence = isBrowser() ? indexedDBLocalPersistence : inMemoryPersistence;

	const auth = initializeAuth(app, { persistence });
	const firestore = initializeFirestore(app, { ignoreUndefinedProperties: true });
	var themeFromLocalStorage = 'dark';

	if (typeof window !== 'undefined') {
		themeFromLocalStorage = localStorage.getItem('theme') || '';
	}

	const [mode, setMode] = React.useState<string>(themeFromLocalStorage);

	const themeName = mode === 'light' ? constants.THEMES.LIGHT : constants.THEMES.DARK;
	toastOptions.style = themeName === constants.THEMES.DARK ? { background: '#333', color: '#fff' } : null;

	const colorMode = React.useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
			}
		}),
		[]
	);

	return (
		<>
			<Head>
				<title>FreewayTeam &bull; Augmentez votre présence en ligne grâce à votre ville virtuelle !</title>
			</Head>
			<Script id="axept-io">
				{`
                if (!window.location.href.includes('emailReport')) {
                  window.axeptioSettings = { clientId: '63e17fa030323fa6ba60ec97', cookiesVersion: 'urbiquest-fr' };
                  (function (d, s) {
                    var t = d.getElementsByTagName(s)[0],
                      e = d.createElement(s);
                    e.async = true;
                    e.src = '//static.axept.io/sdk.js';
                    t.parentNode.insertBefore(e, t);
                  })(document, 'script');
                }
              `}
			</Script>
			<NoSsr>
				<I18nProvider i18n={i18n}>
					<HelmetProvider>
						<FirebaseAppProvider firebaseApp={app}>
							<ReduxProvider store={store}>
								<StyledEngineProvider injectFirst={true}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<BlocksProvider>
											<ColorModeContext.Provider value={colorMode}>
												<ThemeProvider
													theme={createCustomTheme({
														theme: themeName
													})}
												>
													<ParallaxProvider>
														<CssBaseline />
														<Toaster position="bottom-center" toastOptions={toastOptions} />
														<AuthProvider sdk={auth}>
															<FirestoreProvider sdk={firestore}>
																<Component {...pageProps} />
															</FirestoreProvider>
														</AuthProvider>
													</ParallaxProvider>
												</ThemeProvider>
											</ColorModeContext.Provider>
										</BlocksProvider>
									</LocalizationProvider>
								</StyledEngineProvider>
							</ReduxProvider>
						</FirebaseAppProvider>
					</HelmetProvider>
				</I18nProvider>
			</NoSsr>
		</>
	);
};

export default App;
