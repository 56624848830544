import * as React from 'react';

// Tous vos imports ici...

export interface ICustomBlock {
	description: () => string;
	name: () => string;
	key: () => string;
	UserComponent: React.FC<UserComponentProps>;
	AdminComponent?: React.FC;
}

export interface UserComponentProps {
	questionKey: string;
	name: string;
	description: string;
	toolTip: string;
}

interface BlocksContextProps {
	getBlock: (key: string) => ICustomBlock | null;
	blocks: Array<ICustomBlock>;
}

export const BlocksContext: React.Context<BlocksContextProps> = React.createContext<BlocksContextProps>({
	getBlock: () => null,
	blocks: []
});

export const useBlocks = () => React.useContext(BlocksContext);

// Modification pour ajouter `children` aux props de BlocksProvider
interface BlocksProviderProps {
	children: React.ReactNode;
}

export const BlocksProvider: React.FC<BlocksProviderProps> = ({ children }) => {
	const [blocks] = React.useState<Array<ICustomBlock>>([
		// Liste des blocs ici...
	]);

	const getBlock = React.useCallback(
		(key: string) => {
			return blocks.find((i) => i.key() === key);
		},
		[blocks]
	);

	return <BlocksContext.Provider value={{ getBlock, blocks }}>{children}</BlocksContext.Provider>;
};
