import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppThunk } from '../store';
import { billingApi } from '../services/billing';
import { organizationMemberApi } from '../services/organizationMember';
import { organizationsApi } from '../services/organizations';
import { questsApi } from '../services/quests';
import { taskItemApi } from '../services/taskitems';
import { userPrivateApi } from '../services/userProfiles';
import { socialDataApi } from '../services/socialData';
import { storageApi } from '../services/storages';
import { historicalDataApi } from '../services/historical-data';
import { connectorsApi } from '../services/connectors';
import { organizationRoleApi } from '../services/organization-roles';
import { adminApi } from '../services/admins';

interface UserState {
	selectedLanguageCodeAdmin: string;
	isAuthenticated: boolean;
	currentOrganizationId: number;
}

const initialState = {
	selectedLanguageCodeAdmin: 'fr',
	isAuthenticated: false,
	currentOrganizationId: 0
} as UserState;

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsAuthenticated(state: UserState, action: PayloadAction<boolean>): void {
			state.isAuthenticated = action.payload;
		},
		setSelectedLanguageCode(state: UserState, action: PayloadAction<string>): void {
			state.selectedLanguageCodeAdmin = action.payload;
		},
		setCurrentOrganizationId(state: UserState, action: PayloadAction<number>): void {
			state.currentOrganizationId = action.payload;
		},

		clear(state: UserState, action: PayloadAction): void {
			_.forEach(_.keys(initialState), (key: string) => {
				state[key] = initialState[key];
			});
		}
	}
});

export const { reducer } = userSlice;

export const setAuthenticatedUser =
	({ isAuthenticated }: { isAuthenticated: boolean }): AppThunk =>
	async (dispatch): Promise<void> => {
		await dispatch(userSlice.actions.setIsAuthenticated(isAuthenticated));
	};

export const setSelectedLanguageCode =
	(languageCode: string): AppThunk =>
	async (dispatch, getState): Promise<void> => {
		await dispatch(userSlice.actions.setSelectedLanguageCode(languageCode));
	};

export const fetchAllWhenOrganisationIsChanging =
	(organizationId): AppThunk =>
	async (dispatch, getState): Promise<void> => {
		window.localStorage.setItem('organizationId', organizationId.organizationId);
		await dispatch(setCurrentOrganizationId(organizationId.organizationId));
		await Promise.all([
			dispatch(userPrivateApi.util.invalidateTags(['user-profile'])),
			dispatch(billingApi.util.invalidateTags(['billing-entity'])),
			dispatch(organizationsApi.util.invalidateTags(['OrganizationDetails'])),
			dispatch(questsApi.util.invalidateTags(['organization-quests', 'ignored-quests', 'history-quest'])),
			dispatch(taskItemApi.util.invalidateTags(['taskitems', 'taskitemsAccomplishment'])),
			dispatch(socialDataApi.util.invalidateTags(['sn-data'])),
			dispatch(storageApi.util.invalidateTags(['values'])),
			dispatch(historicalDataApi.util.invalidateTags(['historical-data'])),
			dispatch(connectorsApi.util.invalidateTags(['organization-id'])),
			dispatch(
				organizationMemberApi.util.invalidateTags([
					'my-pending-invitations',
					'pending-invitations',
					'role',
					'list-members',
					'dashboard-settings',
					'report-settings',
					'dashboard-actif'
				])
			),
			dispatch(organizationRoleApi.util.invalidateTags(['role'])),
			dispatch(adminApi.util.invalidateTags(['admin-roles', 'admin-accounts', 'admin-permissions']))
		]);
	};

export const setCurrentOrganizationId =
	(localOrganizationId?: number): AppThunk =>
	async (dispatch, getState): Promise<void> => {
		await dispatch(userSlice.actions.setCurrentOrganizationId(localOrganizationId));
	};

export default userSlice;
